import "../../style/single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { EditNote } from '@mui/icons-material';
import { Delete } from '@mui/icons-material';
import { Add } from '@mui/icons-material';
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import { useEffect, useState } from "react";
import MIconButton from "../../components/controls/IconButton";
import { useParams } from 'react-router';
import EditFieldPopup  from "../../components/popup/EditFieldPopup";
import BasicSnackbar from "../../components/BasicSnackbar/BasicSnackbar";
import Controls from "../../components/controls/Controls";
import { Grid } from '@mui/material';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  doc,
  collection,
  getDocs,
  onSnapshot,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db} from "../../firebase";


const moment = require('moment');


const BookingPage = () => {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [editDialogData, setDialogOpen] = useState([]); 
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [per, setPerc] = useState(null);
  const params = useParams();

  useEffect(() => {


    var bookingId = params.bookingId;

    const unsub = onSnapshot(doc(db, "bookings", bookingId), (doc) => {

        setData(doc.data());
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  const closeDialog = () => {
    setDialogOpen([])
  }

  const getCategoryName = () => {
    if(typeof data.category === 'string'){
      if(categories !== undefined){
        for (let i = 0; i < categories.length; i++) {
          if(categories[i].id == data.category) return categories[i].name;
        }
      }
    }
  }

  const editField = (
    title,
    type, 
    collection, 
    objectId, 
    field,
    fieldDisplayName,
    currentValue,
    options
    ) => {

    setDialogOpen({
      isOpen: true,
      title: title,
      collection: collection,
      objectId: objectId,
      type: type,
      field: field,
      fieldDisplayName: fieldDisplayName,
      currentValue: currentValue,
      options:options
    })
  }

  const handleChangePixSnapShowStatus = async (e) => {
    console.log("pressed");
    console.log(params.eventId)
    const ref = doc(db, 'bookings', params.eventId);
    await updateDoc(ref, {
      show_pixSnaps: !(data.show_pixSnaps ?? false),
      last_updated: serverTimestamp(),
    });
  }


  return (
    <>
      <div className="single">
        <Sidebar />
        <div className="singleContainer">
          <Navbar />
          <div className="top">
            <div className="left">
              <h1 className="title con">Booking Information</h1>
              <div className="item block_item">

                <div className="details">
                 
                  <div className="detailItem">
                    <span className="itemKey">Client Name:</span>
                    <span className="itemValue">
                      <b>{data.client_name}</b>
  
                      </span>
                  </div>
                 
                  <div className="detailItem">
                    <span className="itemKey">Client Phone:</span>
                    <span className="itemValue">
                      <b>{data.phone}</b>
  
                      </span>
                  </div>
                 
                  <div className="detailItem">
                    <span className="itemKey">Client Email:</span>
                    <span className="itemValue">
                      <b>{data.email}</b>
  
                      </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Event Time:</span>
                    <span className="itemValue"> 
                      <b>
                      { 
                        (data.event_time == undefined) ? "" : moment(data.event_time.seconds * 1000).format('MMMM Do YYYY, h:mm:ss A')
                      }
                      </b>

                    
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Booking Date:</span>
                    <span className="itemValue"> 
                      <b>{ 
                        (data.entry_time == undefined) ? "" : moment(data.entry_time.seconds * 1000).format('MMMM Do YYYY')
                      }</b>

                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Event type:</span>
                    <span className="itemValue"> 
                      <b>{ 
                        data.event_type
                      }</b>

                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Location:</span>
                    <span className="itemValue"> 
                      <b>{ 
                        data.location
                      }</b>

                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Package:</span>
                    <span className="itemValue">
                      <b>{data.package}</b>

                    </span>
                  </div>

                  {/* <div className="detailItem">
                    <span className="itemKey">Country:</span>
                    <span className="itemValue">USA</span>
                  </div> */}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <EditFieldPopup 
        isOpen = {editDialogData?.isOpen == true} 
        setOpen = {closeDialog} 
        title = {editDialogData.title} 
        type={editDialogData.type} 
        objectId={editDialogData.objectId}
        collection = {editDialogData.collection}
        field = {editDialogData.field}
        fieldDisplayName ={editDialogData.fieldDisplayName}
        currentValue = {editDialogData.currentValue}
        options = {editDialogData.options}
      >  
      </EditFieldPopup>

      <BasicSnackbar 
                open={snackbarOpen}
                onClose={setSnackbarOpen}
                severity="error"
                message={"Upload is " + per + "% done"}
            />
      
    </>
  );
};

export default BookingPage;
