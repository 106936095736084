import React from 'react'
import { Dialog, DialogTitle, DialogActions, DialogContent, makeStyles, Typography } from '@material-ui/core';
import MIconButton from '../controls/IconButton';
import Controls from '../controls/Controls'
import { useForm, Form } from '../../components/useForm';
import { Close } from '@mui/icons-material';
import { Grid } from '@mui/material';
import * as employeeService from "../../services/employeeService";
import Box from '@material-ui/core/Box';
import { auth, db, storage } from "../../firebase";
import dayjs from 'dayjs';
import {
    Timestamp,
    updateDoc,
    collection,
    doc,
    serverTimestamp,
  } from "firebase/firestore";
const useStyles = makeStyles(theme => ({
    // dialogWrapper: {
    //     padding: theme.spacing(2),
    //     position: 'absolute',
    //     top: theme.spacing(5)
    // },
    dialogTitle: {
        paddingRight: '0px'
    }
}))

const genderItems = [
    { id: 'male', title: 'Male' },
    { id: 'female', title: 'Female' },
    { id: 'other', title: 'Other' },
]

export default function EditFieldPopup(props) {

    const {
        isOpen, 
        setOpen, 
        title, 
        type, 
        collection, 
        objectId, 
        field,
        fieldDisplayName,
        currentValue,
        options
    } = props;
    const classes = useStyles();

    const initialFValues = {
        title: currentValue
    }

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
            temp[field] = fieldValues[field] ? "" : "Please enter a valid change"

        setErrors({
            ...temp
        })


        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }
    const handleClose = () =>{
        setErrors({})
        //setValues({})
        setFormLoading(false)
        setOpen(null)
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        formLoading,
        setFormLoading
    } = useForm(initialFValues, true, validate);



    const handleSubmit = async e => {
        e.preventDefault()
        console.log(validate());
        if(validate()){
            
            try {
                setFormLoading(true)
                const ref = doc(db, collection, objectId);

                console.log(values[field]);
                
                //organise data
                const data = {};
                if(type == "string" || type == "select"){
                    data[field] = values[field]
                } else if(type == "datetime"){
                    var date = new Date(values[field].$d);
                    data[field] = Timestamp.fromDate(date)
                }

                console.log(Object.keys(data).length);


                if(Object.keys(data).length > 0){
                    console.log('updating');
                    await updateDoc(ref, {
                        ...data,
                        last_updated: serverTimestamp(),
                      });
                }else{
                    console.log('not updateing');
                }
                handleClose()
              } catch (err) {
                console.log(err);
              }
        }
        
    }

    

    return (
        <Dialog open={isOpen} maxWidth="md" classes={{ paper: classes.dialogWrapper }} onClose={handleClose}>
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        {title ?? "title"}
                    </Typography> 
                    <Box sx={{ paddingRight:20}}>
                        <MIconButton 
                        icon={
                            <Close color="primary" />
                        }
                        onClick = {
                            () => {
                                handleClose()
                            }
                        }
                        />
                    </Box>
                </div>
            </DialogTitle>
            <DialogContent dividers>
            <Form onSubmit={handleSubmit}>
                   <Grid width={500}>
                   {(type == "string") ? <Controls.Input
                        name={field}
                        label={fieldDisplayName}
                        value={values[field] ?? currentValue}
                        onChange={handleInputChange}
                        error={errors[field]}
                    /> : ""}
                    {(type == "radio") ? <Controls.RadioGroup
                        name={field}
                        label="Gender"
                        value={values.gender}
                        onChange={handleInputChange}
                        items={genderItems}
                    /> : ""}
                    {(type == "select") ? <Controls.Select
                        name={field}
                        label={fieldDisplayName}
                        value={values[field] ?? currentValue}
                        onChange={handleInputChange}
                        options={options ?? []}
                        error={errors.departmentId}
                    /> : ""}
                    {(type == "datetime") ? <Controls.MDatePicker
                        name={field}
                        label={fieldDisplayName}
                        value={values[field] ?? dayjs(currentValue)}
                        onChange={handleInputChange}
                    /> : ""}
                    {(type == "checkbox") ? <Controls.MCheckbox
                        name={field}
                        label="Permanent Employee"
                        value={values.isPermanent}
                        onChange={handleInputChange}
                    /> : ""}
                   </Grid>
        </Form>
            </DialogContent>
            <DialogActions>
                <div>
                        <Controls.Button
                            type="Update Data"
                            isLoading={formLoading}
                            onClick={handleSubmit}
                            text={`Update ${fieldDisplayName}`} />
                        <Controls.Button
                            text="Cancel"
                            color="default"
                            onClick={handleClose} />
                    </div>
            </DialogActions>
        </Dialog>
    )
}
