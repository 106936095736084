import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "kitara-media-ce128.firebaseapp.com",
  projectId: "kitara-media-ce128",
  storageBucket: "kitara-media-ce128.appspot.com",
  messagingSenderId: "283283131963",
  appId: "1:283283131963:web:af0c648ace6d315bddd61e",
  measurementId: "G-21P216BXTJ"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

export const logOut = async () => {
  var res = await signOut(auth);
}
