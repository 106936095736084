import "./NewCategory.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import { isValidUrl } from "../../services/util";
import { Grid } from '@mui/material';
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDocs,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { auth, db, storage } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { useForm, Form } from '../../components/useForm';
import Controls from '../../components/controls/Controls'





const NewCategory = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate()

  useEffect(() => {


  }, []);


  const initialFValues = {
    name: '',
}


const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('name' in fieldValues){
          temp.name = fieldValues.name ? "" : "Category name is required."
        }

        if ('web_url' in fieldValues){
          if(fieldValues.web_url.length > 0){
            temp.web_url = isValidUrl(fieldValues.web_url) ? "":"Please enter a valid url"
          }else{
            temp.web_url = "";
          }
        }
            
        setErrors({
            ...temp
        })


        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
}


const {
  values,
  setValues,
  errors,
  setErrors,
  handleInputChange,
  resetForm,
  formLoading,
  setFormLoading
} = useForm(initialFValues, true, validate);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value });
  };


  const handleSubmit = async e => {
    e.preventDefault()
    if(validate()){

      const data = values;
        
      try {
        await addDoc(collection(db, "categories"), {
          ...data,
          timeStamp: serverTimestamp(),
          last_updated:serverTimestamp(),
        });
        navigate(-1);
      } catch (err) {
        console.log(err);
      }
  }
}

const priorityChanged = (e) => {
  const value = e.target.value.replace(/\D/g, "");
  e.target.value = value;
  handleInputChange(e);
}


  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />

        <h1>New category</h1>

        <div className="bottom pg_body">
          
          <div className="left">
            <form className="create_form">
              <div className="form_content">
            
              <Grid container className="form_fields">
                <Grid item sm={12} md={6}>
                    <Controls.Input
                        name="name"
                        label="Category name"
                        onChange={handleInputChange}
                        error={errors.title}
                    />

                    <Controls.Input
                        name="priority"
                        label="Priority (Optional)"
                        value={values.priority}
                        onChange={priorityChanged}
                        error={errors.priority}
                    />
                    <Controls.Input
                        name="web_url"
                        label="Web URL (optional)"
                        onChange={handleInputChange}
                        error={errors.web_url}
                    />
                    
                    

                </Grid>
            </Grid>
              </div>
              <div>
                
              </div>
            </form>
            <div className="button_row">
            <Controls.MActionButton onClick={handleSubmit}>
                  {"Create category"}
              </Controls.MActionButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCategory;
