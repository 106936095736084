import "./NewClip.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { isValidUrl } from "../../services/util";
import { Grid } from '@mui/material';
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDocs,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { auth, db, storage } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { useForm, Form } from '../../components/useForm';
import Controls from '../../components/controls/Controls';
import { useParams } from 'react-router';





const NewClip = () => {
  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const navigate = useNavigate()
  const params = useParams();

  useEffect(() => {
    const fetchData = async () => {

    };

    fetchData();

  }, []);
  useEffect(() => { 
    const uploadFile = () => {
      const name = new Date().getTime() +"_"+ file.name.replace(/\s/g, "X").replace().replace(/[^\w\s.]/gi, '');


      const storageRef = ref(storage, name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('the image url below');
            console.log(downloadURL);
            values.thumb_nail = downloadURL;
          
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  const initialFValues = {
    video_url: '',
}


const validate = (fieldValues = values) => {
        console.log('values below');
        console.log(values);
        let temp = { ...errors }

        if ('video_url' in fieldValues){
          temp.video_url = isValidUrl(fieldValues.video_url) ? "":"Please enter a valid url"
          
        }


        
        setErrors({
            ...temp
        })


        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
}


const {
  values,
  setValues,
  errors,
  setErrors,
  handleInputChange,
  resetForm,
  formLoading,
  setFormLoading
} = useForm(initialFValues, true, validate);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value });
  };


  const handleSubmit = async e => {
    e.preventDefault()
    if(validate()){

      const clipData = values;

      console.log(values);
        
      try {
        await addDoc(collection(db, `events/${params.eventId}/pix_clips`), {
          ...clipData,
          timeStamp: serverTimestamp(),
        });
        navigate(-1);
      } catch (err) {
        console.log(err);
      }
  }else{
    console.log('did not pass the validation')
  }
}


  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />

        <h1>Add new clip</h1>

        <div className="bottom pg_body">
          
          <div className="left">
            <form className="create_form">
              <div className="form_content">
              <div className="img_section">
            <label htmlFor="file">
                    <div className="img_holdr" style={{ 
                        display: "block", 
                        backgroundImage:  "url(" + ((file) ?  URL.createObjectURL(file) :"https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg") + ")" 
                      }}>"
                    </div>
                  </label>
                  <input
                          type="file"
                          id="file"
                          onChange={(e) => setFile(e.target.files[0])}
                          style={{ display: "none" }}
                    />
            </div>
              <Grid container className="form_fields">
                <Grid item sm={12} md={6}>
                <Controls.Input
                        name="video_url"
                        label="Video URL"
                        onChange={handleInputChange}
                        error={errors.video_url}
                    />


                </Grid>
            </Grid>
              </div>
              <div>
                
              </div>
            </form>
            <div className="button_row">
            <Controls.MActionButton onClick={handleSubmit}>
                  {"Add Clip"}
              </Controls.MActionButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewClip;
