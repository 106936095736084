import React from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


export default function MDatePicker(props) {

    const { name, label, value, onChange, error=null} = props


    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker disableToolbar variant="inline" inputVariant="outlined"
                label={label}
                format="DD-MM-YYYY hh:mm A"
                name={name}
                value={value}
                error={error && false}

                onChange={date =>onChange(convertToDefEventPara(name,date))}
                {...(error && {slotProps:{textField:{error:true,helperText:error}}})}

            />
        </LocalizationProvider>
    )
}
