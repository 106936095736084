import "../../style/single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { EditNote } from '@mui/icons-material';
import { Delete } from '@mui/icons-material';
import { Add } from '@mui/icons-material';
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import { useEffect, useState } from "react";
import MIconButton from "../../components/controls/IconButton";
import { useParams } from 'react-router';
import EditFieldPopup  from "../../components/popup/EditFieldPopup";
import BasicSnackbar from "../../components/BasicSnackbar/BasicSnackbar";
import Controls from "../../components/controls/Controls";
import { Grid } from '@mui/material';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { eventColumns, userColumns, userRows } from "../../datatablesource";
import { Link } from "react-router-dom";
import {
  doc,
  collection,
  getDocs,
  onSnapshot,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  firestore
} from "firebase/firestore";
import { db, storage} from "../../firebase";


const moment = require('moment');


const EventPage = () => {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [clips, setClips] = useState([]);
  const [editDialogData, setDialogOpen] = useState([]); 
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [per, setPerc] = useState(null);
  const params = useParams();

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        const querySnapshot = await getDocs(collection(db, "categories"));
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setCategories(list);

      } catch (err) {
        console.log(err);
      }
    };
    fetchData();

    var eventId = params.eventId;

    const unsub = onSnapshot(doc(db, "events", eventId), (doc) => {

        setData(doc.data());
      },
      (error) => {
        console.log(error);
      }
    );

    
    
    const unsubClips = onSnapshot(collection(db, `events/${eventId}/pix_clips`), (clips) => {
        let clipsData = [];

        for (let i = 0; i < clips.docs.length; i++) {
          let dta = clips.docs[i].data();
          dta['id'] = clips.docs[i].id;
          clipsData.push(dta);
          
        }



        setClips(clipsData);
        console.log(clipsData)
      },
      (error) => {
        console.log(error);
      }
    );

    

    return () => {
      unsub();
      unsubClips();
    };
  }, []);

  const closeDialog = () => {
    setDialogOpen([])
  }

  const getCategoryName = () => {
    if(typeof data.category === 'string'){
      if(categories !== undefined){
        for (let i = 0; i < categories.length; i++) {
          if(categories[i].id == data.category) return categories[i].name;
        }
      }
    }
  }

  const editField = (
    title,
    type, 
    collection, 
    objectId, 
    field,
    fieldDisplayName,
    currentValue,
    options
    ) => {

    setDialogOpen({
      isOpen: true,
      title: title,
      collection: collection,
      objectId: objectId,
      type: type,
      field: field,
      fieldDisplayName: fieldDisplayName,
      currentValue: currentValue,
      options:options
    })
  }

  const handleChangePixSnapShowStatus = async (e) => {
    const ref = doc(db, 'events', params.eventId);
    await updateDoc(ref, {
      show_pixSnaps: !(data.show_pixSnaps ?? false),
      last_updated: serverTimestamp(),
    });
  }  
  
  const handleChangePixClipShowStatus = async (e) => {
    const ref = doc(db, 'events', params.eventId);
    await updateDoc(ref, {
      show_pixClips: !(data.show_pixClips ?? false),
      last_updated: serverTimestamp(),
    });
  }

  const handleChangeShowStreamStatus = async (e) => {

    const ref = doc(db, 'events', params.eventId);
    await updateDoc(ref, {
      show_stream: !(data.show_stream ?? false),
      last_updated: serverTimestamp(),
    });
  }

  const deletePix = async (index) => {
    try {
      const ref = doc(db, "events", params.eventId);
      let snaps = data.pix_snaps ?? [];
      snaps.splice(index, 1);
      await updateDoc(ref, {
        pix_snaps: snaps,
        last_updated: serverTimestamp(),
      });
    } catch (err) {
      console.log(err);
    }
  }  

  const createNewClip = async () => {
    console.log('attempting to create new clip');
  }
  
  const deleteClip = async (clipId) => {
    try {
      const ref = doc(db, `events/${params.eventId}/pix_clips`, clipId);
      await deleteDoc(ref);
    } catch (err) {
      console.log(err);
    }
  }


  const uploadPix = (file) => {
    const name = new Date().getTime() +"_"+ file.name.replace(/\s/g, "X").replace().replace(/[^\w\s.]/gi, '');
    const storageRef = ref(storage, name);
    const uploadTask = uploadBytesResumable(storageRef, file);
    setSnackbarOpen(true);
  
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        setPerc(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('the image url below');
          console.log(downloadURL);
          var snaps = data.pix_snaps ?? [];
          snaps.push(downloadURL);
          const eventRef = doc(db, 'events', params.eventId);
          updateDoc(eventRef, {
            pix_snaps: snaps,
            last_updated: serverTimestamp(),
          });
        
        });
      }
    );
  };

  return (
    <>
      <div className="single">
        <Sidebar />
        <div className="singleContainer">
          <Navbar />
          <div className="top">
            <div className="left">
              <h1 className="title con" style={{ color: "#000" }}>Event Information</h1>
              <div className="detailItem">
                    <span className="itemValue">
                    <div className="">
                <Controls.MCheckbox
                              name="show_stream"
                              size="small"
                              label="Show Livestream if available"
                              value={data.show_stream ?? true}
                              onChange={handleChangeShowStreamStatus}
                          />
              </div>

                    </span>
                  </div>
              <div className="item block_item">
                <img
                  src={`${data.image_url}`}
                  alt=""
                  className="objImg"
                />
                <div className="details">
                  
                <div className="detailItem">
                    <span className="itemKey">Event title:</span>
                    <span className="itemValue"> <h1 className="itemTitle const_width_text">
                    {data.title} 
                    <MIconButton 
                      icon={
                        <EditNote color="primary" />
                      }
                      onClick = {
                        () => {
                          editField(
                            "Edit event title",
                            "string",
                            "events",
                            params.eventId,
                            "title",
                            "event title",
                            data.title
                          )
                        }
                      }
                    />
                  </h1></span>
                  </div>
                 
                  <div className="detailItem">
                    <span className="itemKey">Start Time:</span>
                    <span className="itemValue">
                      { 
                        (data.start_time == undefined) ? "" : moment(data.start_time.seconds * 1000).format('MMMM Do YYYY, h:mm:ss A')
                      }
                      <MIconButton 
                      icon={
                        <EditNote color="primary"/>
                      }
                      onClick = {
                        () => {
                          editField(
                            "Edit start time",
                            "datetime",
                            "events",
                            params.eventId,
                            "start_time",
                            "Start time",
                            data.start_time.toDate()
                          )
                        }
                      }
                    />  
                      </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">End Time:</span>
                    <span className="itemValue"> 
                      { 
                        (data.end_time == undefined) ? "" : moment(data.end_time.seconds * 1000).format('MMMM Do YYYY, h:mm:ss A')
                      }
                      <MIconButton 
                      icon={
                        <EditNote color="primary"/>
                      }
                      onClick = {
                        () => {
                          editField(
                            "Edit end time",
                            "datetime",
                            "events",
                            params.eventId,
                            "end_time",
                            "End time",
                            data.end_time.toDate()
                          )

                        }
                      }
                    />  
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Host:</span>
                    <span className="itemValue"> 
                      { 
                        data.host
                      }
                      <MIconButton 
                      icon={
                        <EditNote color="primary"/>
                      }
                      onClick = {
                        () => {
                          editField(
                            "Edit host name",
                            "string",
                            "events",
                            params.eventId,
                            "host",
                            "Host name",
                            data.host
                          )

                        }
                      }
                    />  
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Category:</span>
                    <span className="itemValue">
                      <b>{getCategoryName()}</b>
                      <MIconButton 
                      icon={
                        <EditNote color="primary"/>
                      }
                      onClick = {
                        () => {
                          editField(
                            "Select category",
                            "select",
                            "events",
                            params.eventId,
                            "category",
                            "Event category",
                            data.category,
                            categories
                          )

                        }
                      }
                    />  
                    </span>
                  </div>

                  <div className="detailItem">
                    <span className="itemKey">Stream URL:</span>
                    <span className="itemValue"> 
                      <Link to={data.live_url}>
                        {data.live_url}
                      </Link>
                      <MIconButton 
                      icon={
                        <EditNote color="primary"/>
                      }
                      onClick = {
                        () => {
                          editField(
                            "Edit streaming link",
                            "string",
                            "events",
                            params.eventId,
                            "live_url",
                            "Stream URL",
                            data.live_url
                          )

                        }
                      }
                    />  
                    </span>
                  </div>
                  
                  {/* <div className="detailItem">
                    <span className="itemKey">Country:</span>
                    <span className="itemValue">USA</span>
                  </div> */}
                </div>
              </div>
            </div>

          </div>
          <div className="bottom">
            <div className="row_apart">
              <h1 className="title text-black no_break bx_tit">Pix Snaps</h1>
              
              <div className="cb_bx">
                <Controls.MCheckbox
                              name="show_stream"
                              size="small"
                              label="Show PixSnaps"
                              value={data.show_pixSnaps ?? true}
                              onChange={handleChangePixSnapShowStatus}
                          />
              </div>
            </div>

            <div>
              <Grid container rowSpacing={3} >
              {
                (data.pix_snaps ?? []).map((pix, index) => (
                  <Grid item sm={6} md={3} xs={12} key={"pix_" + index} onClick={(() => {
                    deletePix(index)
                  })}>
                    <div className="pix_item" style={{ backgroundImage:  "url(" + pix + ")" }}>
                    <MIconButton 
                      icon={
                        <Delete color="error"/>
                      }
                      onClick = {
                        () => {
                          
                        }
                      }
                    /> 
                    </div>
                    
                    
              </Grid>
                ))
              }
                  
                  
              <Grid item sm={6} md={3} xs={12}>
                <label htmlFor="file"><div className="pix_item">
                    
                      <div className="pix_placeholder">
                      <Add color="disabled" fontSize="large"/>
                      </div>
                    </div>

                  </label>
                    
                    
              </Grid>
              </Grid>
              <input
                          type="file"
                          id="file"
                          onChange={(e) => uploadPix(e.target.files[0])}
                          style={{ display: "none" }}
                    />
            </div>
            
            <div className="v_spacer"></div>

            <div className="row_apart">
              <h1 className="title text-black no_break bx_tit">Pix Clips</h1>
              
              <div className="cb_bx">
                <Controls.MCheckbox
                              name="show_stream"
                              size="small"
                              label="Show PixClips"
                              value={data.show_pixClips ?? true}
                              onChange={handleChangePixClipShowStatus}
                          />
              </div>
            </div>

            <div>
              <Grid container rowSpacing={3} >
              {
                (clips ?? []).map((clip, index) => (
                  <Grid item sm={6} md={3} xs={12} key={"pix_" + index} onClick={(() => {
                    deleteClip(clip.id)
                  })}>
                    <div className="pix_item" style={{ backgroundImage:  "url(" + clip.thumb_nail + ")" }}>
                    <MIconButton 
                      icon={
                        <Delete color="error"/>
                      }
                      onClick = {
                        () => {
                          
                        }
                      }
                    /> 
                    </div>
                    
                    
              </Grid>
                ))
              }
                  
                  
              <Grid item sm={6} md={3} xs={12}>
                
                <Link to={`/new_clip/${params.eventId}`}>
                  <div className="pix_item">
                      <div className="pix_placeholder">
                      <Add color="disabled" fontSize="large"/>
                      </div>
                    </div>

                  </Link>
                    
                    
              </Grid>
              </Grid>
            </div>
            
            
          </div>
        </div>
      </div>
      <EditFieldPopup 
        isOpen = {editDialogData?.isOpen == true} 
        setOpen = {closeDialog} 
        title = {editDialogData.title} 
        type={editDialogData.type} 
        objectId={editDialogData.objectId}
        collection = {editDialogData.collection}
        field = {editDialogData.field}
        fieldDisplayName ={editDialogData.fieldDisplayName}
        currentValue = {editDialogData.currentValue}
        options = {editDialogData.options}
      >  
      </EditFieldPopup>

      <BasicSnackbar 
                open={snackbarOpen}
                onClose={setSnackbarOpen}
                severity="error"
                message={"Upload is " + per + "% done"}
            />
      
    </>
  );
};

export default EventPage;
