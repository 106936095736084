import React from 'react'
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';


// const useStyles = makeStyles(theme => ({
//     root: {
//         minWidth: 0,
//         margin: 14
//     },
//     secondary: {
//         backgroundColor: theme.palette.secondary.light,
//         '& .MuiButton-label': {
//             color: theme.palette.secondary.main,
//         }
//     },
//     primary: {
//         backgroundColor: theme.palette.primary.light,
//         '& .MuiButton-label': {
//             color: theme.palette.primary.main,
//         }
//     },
// }))

export default function MActionButton(props) {

    const { color, children, onClick } = props;
    //const classes = useStyles();

    return (
        <Button
            //className={`${classes.root} ${classes[color]}`}
            color={color ?? "primary"}
            variant='contained'
            style={{ backgroundColor:"#1876D2", padding:"10px 14px", color:"#fff"}}
            onClick={onClick}>
            {children}
        </Button>
        
    )
}
