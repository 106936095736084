import "../../style/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { categoryColumns, userRows } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import MActionButton from "../controls/ActionButton";
import EditFieldPopup from "../popup/EditFieldPopup";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import Button from "../controls/Button";

const CategoryTable = () => { 
  const [data, setData] = useState([]);
  const [editDialogData, setDialogOpen] = useState([]); 

  useEffect(() => {
    const unsub = onSnapshot(
      collection(db, "categories"),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setData(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  const closeDialog = () => {
    setDialogOpen([])
  }

  const editField = (
    title,
    type, 
    collection, 
    objectId, 
    field,
    fieldDisplayName,
    currentValue,
    options
    ) => {

    setDialogOpen({
      isOpen: true,
      title: title,
      collection: collection,
      objectId: objectId,
      type: type,
      field: field,
      fieldDisplayName: fieldDisplayName,
      currentValue: currentValue,
      options:options
    })
  }




  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      flex:1,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Button text="Update name" size="small" onClick = {
                        () => {
                          editField(
                            "Edit category name",
                            "string",
                            "categories",
                            params.row.id,
                            "name",
                            "Category name",
                            params.row.name
                          )
                        }
                      }>
            </Button>
          </div>
        );
      },
    },
  ];




  return (
    <><div className="datatable">
      <div className="datatableTitle">
        App Categories
        <Link to="/categories/new">
          <MActionButton>
            {"Create new category"}
          </MActionButton>
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={categoryColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
        disableSelectionOnClick
      />
    </div>

    <EditFieldPopup 
        isOpen = {editDialogData?.isOpen == true} 
        setOpen = {closeDialog} 
        title = {editDialogData.title} 
        type={editDialogData.type} 
        objectId={editDialogData.objectId}
        collection = {editDialogData.collection}
        field = {editDialogData.field}
        fieldDisplayName ={editDialogData.fieldDisplayName}
        currentValue = {editDialogData.currentValue}
        options = {editDialogData.options}
      >  
      </EditFieldPopup>
    </>
  );
};

export default CategoryTable;
