import React from 'react'
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';


// const useStyles = makeStyles(theme => ({
//     root: {
//         minWidth: 0,
//         margin: theme.spacing(0.5)
//     },
//     secondary: {
//         backgroundColor: theme.palette.secondary.light,
//         '& .MuiButton-label': {
//             color: theme.palette.secondary.main,
//         }
//     },
//     primary: {
//         backgroundColor: theme.palette.primary.light,
//         '& .MuiButton-label': {
//             color: theme.palette.primary.main,
//         }
//     },
// }))

export default function MIconButton(props) {

    const { color, icon, onClick } = props;
    //const classes = useStyles();

    return (
        <IconButton
            //className={`${classes.root} ${classes[color]}`}
            onClick={onClick}>
            {icon}
        </IconButton>
    )
}
