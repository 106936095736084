import "./NewEvent.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import { isValidUrl } from "../../services/util";
import { Grid } from '@mui/material';
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDocs,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { auth, db, storage } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { useForm, Form } from '../../components/useForm';
import Controls from '../../components/controls/Controls'





const NewEvent = () => {
  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [categories, setCategories] = useState([]);
  const [per, setPerc] = useState(null);
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        const querySnapshot = await getDocs(collection(db, "categories"));
        let i = 0;
        querySnapshot.forEach((doc) => {
          i++;
          list.push({ id: doc.id, key: i,...doc.data() });
        });
        if(list.length > 0){
          values.category = list[0].id
        }
        
        setCategories(list);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();

  }, []);
  useEffect(() => { 
    const uploadFile = () => {
      const name = new Date().getTime() +"_"+ file.name.replace(/\s/g, "X").replace().replace(/[^\w\s.]/gi, '');


      const storageRef = ref(storage, name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('the image url below');
            console.log(downloadURL);
            values.image_url = downloadURL;
          
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  const initialFValues = {
    title: '',
    start_time: null,
    end_time:null,
    host:"Kitara Media",
    web_url: "",
    live_url: "",
    show_stream: true

}


const validate = (fieldValues = values) => {
        console.log('values below');
        console.log(values);
        let temp = { ...errors }
        if ('title' in fieldValues){
          temp.title = fieldValues.title ? "" : "Event title is required."
        }

        if ('host' in fieldValues){
          temp.host = fieldValues.host ? "" : "Event host is required."
        }

        if ('start_time' in fieldValues){
          temp.start_time = fieldValues.start_time ? "":"Please select a start date & time"
        }
        if ('end_time' in fieldValues){
          temp.end_time = fieldValues.end_time ? "":"Please select an end date & time"
        }

        if ('web_url' in fieldValues){
          if(fieldValues.web_url.length > 0){
            temp.web_url = isValidUrl(fieldValues.web_url) ? "":"Please enter a valid url"
          }else{
            temp.web_url = "";
          }
        }
        if ('live_url' in fieldValues){
          if(fieldValues.live_url.length > 0){
            temp.live_url = isValidUrl(fieldValues.live_url) ? "":"Please enter a valid url"
          }else{
            temp.live_url = "";
          }
        }
        if ('departmentId' in fieldValues)
            temp.departmentId = fieldValues.departmentId.length != 0 ? "" : "This field is required."
            
        // if ('email' in fieldValues)
        //     temp.email = (/$^|.+@.+..+/).test(fieldValues.email) ? "" : "Email is not valid."
        // if ('mobile' in fieldValues)
        //     temp.mobile = fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required."
        
        setErrors({
            ...temp
        })


        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
}


const {
  values,
  setValues,
  errors,
  setErrors,
  handleInputChange,
  resetForm,
  formLoading,
  setFormLoading
} = useForm(initialFValues, true, validate);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value });
  };


  const handleSubmit = async e => {
    e.preventDefault()
    if(validate()){

      const eventData = values;
      eventData.start_time = Timestamp.fromDate(eventData.start_time.toDate());
      eventData.end_time = Timestamp.fromDate(eventData.end_time.toDate());
        
      try {
        await addDoc(collection(db, "events"), {
          ...eventData,
          timeStamp: serverTimestamp(),
          last_updated:serverTimestamp(),
        });
        navigate(-1);
      } catch (err) {
        console.log(err);
      }
  }
}


  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />

        <h1>Create new event</h1>

        <div className="bottom pg_body">
          
          <div className="left">
            <form className="create_form">
              <div className="form_content">
              <div className="img_section">
            <label htmlFor="file">
                    <div className="img_holdr" style={{ 
                        display: "block", 
                        backgroundImage:  "url(" + ((file) ?  URL.createObjectURL(file) :"https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg") + ")" 
                      }}>"
                    </div>
                  </label>
                  <input
                          type="file"
                          id="file"
                          onChange={(e) => setFile(e.target.files[0])}
                          style={{ display: "none" }}
                    />
            </div>
              <Grid container className="form_fields">
                <Grid item sm={12} md={6}>
                    <Controls.Input
                        name="title"
                        label="Event title"
                        onChange={handleInputChange}
                        error={errors.title}
                    />

                    <Controls.Input
                        name="host"
                        label="Event host"
                        value={values.host}
                        onChange={handleInputChange}
                        error={errors.host}
                    />
                   {
                    categories.length > 0 && <Controls.Select
                    name="category"
                    label="Category"
                    value={values.category}
                    onChange={handleInputChange}
                    options={categories}
                />
                   }
                    <Controls.Input
                        name="web_url"
                        label="Web URL (optional)"
                        onChange={handleInputChange}
                        error={errors.web_url}
                    />
                    
                    

                </Grid>
                <Grid item sm={12} md={6}>
                  <Controls.MDatePicker
                        name="start_time"
                        label="Start Date & Time"
                        onChange={handleInputChange}
                        error={errors.start_time}
                    />
                    <Controls.MDatePicker
                        name="end_time"
                        label="End Date & Time"
                        onChange={handleInputChange}
                        error={errors.end_time}
                    />
                    {/* <Controls.RadioGroup
                        name="gender"
                        label="Gender"
                        value={values.gender}
                        onChange={handleInputChange}
                        items={[{id:"1", title:"Value 1"},{id:"2", title:"Value 2"},{id:"3", title: "Value 3"}]}
                    /> */}

                    <Controls.Input
                        name="live_url"
                        label="Livestream URL (optional)"
                        onChange={handleInputChange}
                        error={errors.live_url}
                    />
                    <Controls.MCheckbox
                        name="show_stream"
                        label="Show Livestream if available"
                        value={values.show_stream}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>
              </div>
              <div>
                
              </div>
            </form>
            <div className="button_row">
            <Controls.MActionButton onClick={handleSubmit}>
                  {"Create event"}
              </Controls.MActionButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewEvent;
