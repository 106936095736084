import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import MCheckbox from "./Checkbox";
import MDatePicker from "./DatePicker";
import Button from "./Button";
import MActionButton from "./ActionButton";

const Controls = {
    Input,
    RadioGroup,
    Select,
    MCheckbox,
    MDatePicker,
    Button,
    MActionButton,
}

export default Controls;