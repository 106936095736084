import "./userlist.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import CategoryTable from "../../components/categorytable/Categorytable"

const UserList = () => {

  
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <CategoryTable/>
      </div>
    </div>
  )
}

export default UserList