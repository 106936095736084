import { Link } from 'react-router-dom';
import { isValidUrl } from "./services/util";

const moment = require('moment');

export const categoryColumns = [

  {
    field: "name",
    headerName: "name",
    width: 230,
  },

  {
    field: "priority",
    headerName: "Priority",
    width: 160,
    renderCell: (params) => {
      return (
        <div>
          <b>{params.row.priority ?? "--"}</b>
        </div>
      );
    },
  },

  {
    field: "web_url",
    headerName: "Web URL",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {isValidUrl(params.row.web_url) && <Link to={params.row.web_url} target='__blank'>External link</Link>}
        </div>
      );
    },
  },
];


export const userColumns = [
  {
    field: "user",
    headerName: "User",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.photoURL} alt="" />
          {params.row.displayName}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },

  {
    field: "time",
    headerName: "Time Created",
    width: 160,
    renderCell: (params) => {
      return (
        <div>
          {params.row.timeStamp.toDate().toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric'})}
        </div>
      );
    },
  },

  {
    field: "time2",
    headerName: "Last seen",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.timeStamp.toDate().toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric'})}
        </div>
      );
    },
  },
];


export const eventColumns = [
  {
    field: "user",
    headerName: "Event Title",
    width: 300,
    flex:2,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg2" src={params.row.image_url} alt="" />
          <b style={{ whiteSpace: "normal", fontSize: "16px"}}>{params.row.title}</b>
        </div>
      );
    },
  },
  {
    field: "start_time",
    headerName: "Start time",
    width: 230,
    renderCell: (params) => {
      var startTime = params.row.start_time == undefined ? null : params.row.start_time.seconds * 1000;
      return (
        <div>
          {startTime == null ? "Not stated" : moment(startTime).format('MMMM Do YYYY, h:mm:ss A')}
        </div>
      );
    },
  },

  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      var startTimeStamp = params.row.start_time == undefined ? null : params.row.start_time.seconds;
      var endTimeStamp = params.row.end_time == undefined ? null : params.row.end_time.seconds;
      var now = Math.floor((new Date()).getTime() / 1000)

      var status = "";
      var statusText = "Unknown";

      if(startTimeStamp > 0){
        if(endTimeStamp > 0){
          if(startTimeStamp > now){
            statusText = "Upcoming"
          }else{
            if(endTimeStamp > now){
              statusText = "Ongoing"
            }else{
              statusText = "Finished"
            }
          }
        }
      }

      return (
        <div className={`cellWithStatus ${status}`}>
          {statusText}
        </div>
      );
    },
  },
];

export const bookingColumns = [

  {
    field: "clent_name",
    headerName: "Client Name",
    width: 230,
    renderCell: (params) => {
      return (
        <b>
          {params.row.client_name}
        </b>
      );
    },
  },
  {
    field: "booking_time",
    headerName: "Created",
    width: 230,
    renderCell: (params) => {
      var date = params.row.entry_time == undefined ? null : params.row.entry_time.seconds * 1000;
      return (
        <div>
          {date == null ? "Not stated" : moment(date).format('MMMM Do YYYY')}
        </div>
      );
    },
  },

  {
    field: "event_time",
    headerName: "Event time",
    width: 230,
    renderCell: (params) => {
      var startTime = params.row.event_time == undefined ? null : params.row.event_time.seconds * 1000;
      return (
        <b>
          <div>
          {startTime == null ? "Not stated" : moment(startTime).format('MMMM Do YYYY, h:mm:ss A')}
        </div>
        </b>
      );
    },
  },  
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      var startTimeStamp = params.row.start_time == undefined ? null : params.row.start_time.seconds;
      var endTimeStamp = params.row.end_time == undefined ? null : params.row.end_time.seconds;
      var now = Math.floor((new Date()).getTime() / 1000)

      var status = "";
      var statusText = "Unknown";

      if(startTimeStamp > 0){
        if(endTimeStamp > 0){
          if(startTimeStamp > now){
            statusText = "Upcoming"
          }else{
            if(endTimeStamp > now){
              statusText = "Ongoing"
            }else{
              statusText = "Finished"
            }
          }
        }
      }

      return (
        <div className={`cellWithStatus ${status}`}>
          {statusText}
        </div>
      );
    },
  },
];
