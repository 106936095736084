import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import UserList from "./pages/userlist/UserList";
import EventList from "./pages/eventlist/EventList";
import CategoryList from "./pages/categorylist/CategoryList";
import BookingList from "./pages/bookingslist/BookingsList";
import Single from "./pages/single/Single";
import EventPage from "./pages/eventpage/EventPage";
import New from "./pages/new/New";
import NewEvent from "./pages/newevent/NewEvent";
import NewClip from "./pages/newclip/NewClip";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { productInputs, userInputs } from "./formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/AuthContext";
import BookingPage from "./pages/bookingpage/BookingPage";
import NewCategory from "./pages/newcategory/NewCategory";

function App() {
  const { darkMode } = useContext(DarkModeContext);

  const {currentUser} = useContext(AuthContext)

  const RequireAuth = ({ children }) => {

    return currentUser ? children : <Navigate to="/login" />;
  };

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="login" element={<Login />} />
            <Route
              index
              element={
                <RequireAuth>
                  <EventList />
                </RequireAuth>
              }
            />
            <Route path="users">
              <Route
                index
                element={
                  <RequireAuth>
                    <UserList />
                  </RequireAuth>
                }
              />
              <Route
                path=":userId"
                element={
                  <RequireAuth>
                    <Single />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <New inputs={userInputs} title="Add New User" />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="bookings">
              <Route
                index
                element={
                  <RequireAuth>
                    <BookingList />
                  </RequireAuth>
                }
              />
              <Route
                path=":bookingId"
                element={
                  <RequireAuth>
                    <BookingPage />
                  </RequireAuth>
                }
              />

            </Route>
            <Route
                    path="new_clip/:eventId"
                    element={
                      <RequireAuth>
                        <NewClip />
                      </RequireAuth>
                    }
                  />
            <Route path="events">
              <Route
                index
                element={
                  <RequireAuth>
                    <EventList />
                  </RequireAuth>
                }
              />
              <Route
                path=":eventId"
                element={
                  <RequireAuth>
                    <EventPage />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <NewEvent />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="categories">
              <Route
                index
                element={
                  <RequireAuth>
                    <CategoryList />
                  </RequireAuth>
                }
              />
              <Route
                path=":eventId"
                element={
                  <RequireAuth>
                    <EventPage />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <NewCategory />
                  </RequireAuth>
                }
              />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
